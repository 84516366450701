/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import Banner from 'components/usability-testing/banner';
import arrow from 'img/ui/arrow_white.svg';

const HeatMapSoftware = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query HeatMapSoftware {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "heatmaps/heat-map-software" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>6 types of tools to use with your heat map software (with examples)</h2>

      <p>
        One of the distinguishing characteristics of the best heatmap software is the variety of
        tools they integrate with. Below are a few types you should particularly show interest in.
      </p>
      <h3>#1 Session recording software</h3>

      <p>
        Simply said, session recording and{' '}
        <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
          heatmap
        </Link>{' '}
        software are like a match made in heaven, as they complement each other ideally. While heat
        maps give you a bird’s eye view of all user actions within a given page, session recording
        software lets you dive in and replay each user activity.{' '}
      </p>

      <p>
        To give you a sense of how it works, let’s consider a scenario for an eCommerce store. Your
        heatmap indicates that a lot of users click on the “proceed to checkout” button, but only 5%
        of them finalized the purchase within the last week. If your{' '}
        <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
          heatmap analysis
        </Link>{' '}
        rules out any broken buttons or rage clicks, it’s time for further investigation. After
        watching a couple of recordings, you notice an error – the items keep disappearing from the
        cart. After trying two or three times, most users simply lose motivation and give up.
      </p>

      <h4>What tools to use?</h4>

      <p>
        We encourage you to try out{' '}
        <Link href="/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </Link>
        , as it offers both session replay and heat map software. The best part is you get to see a
        3-in-1 view, i.e., as you watch the recording of a user’s session, you also see a
        semi-transparent overlay of the areas with the overall highest number of activities and
        clicks on the site.
      </p>

      <Img
        fluid={images.heatmap.node.childImageSharp.fluid}
        alt="LiveSession's heat map software provides an all-in-one view of session recordings, clicks, and most noticed areas of your website."
        title="What tools to use?"
      />
      <p>
        If you’d like to see this in the works, you can try LiveSession on a{' '}
        <Link signUp sourceID="text" target="_blank" rel="noopener noreferrer">
          free trial
        </Link>
        .
      </p>
      <h3>#2 Website analytics</h3>

      <p>
        Website analytics tools are essential if you want to make the most of your heat maps. How
        so? They store key website stats such as each page’s bounce and conversion rate, CTR, and
        session duration. This means they help you decide which pages – other than your homepage and
        pricing – would make good candidates for a heat map.
      </p>

      <p>
        In your website analytics platform, you might, for instance, find an important landing page
        that has very few signups or notice that your entire blog has exceptionally high bounce
        rates. By setting up a heat map on them, you might be able to notice whether your CTAs are
        getting notices and clicks, or how far down the page readers scroll before dropping from it.
      </p>
      <Img
        fluid={images.ganalytics.node.childImageSharp.fluid}
        alt="Google Analytics is a website analytics tool you can integrate with best heatmap tools, including LiveSession"
        title="Website analytics"
      />
      <p>
        Source:{' '}
        <Link
          href="https://contentmarketinginstitute.com/wp-content/uploads/2019/04/blog-traffic-google-analytics.png"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Content Marketing Institute
        </Link>
      </p>

      <h4>What tools to use?</h4>
      <p>
        As far as website analytics are concerned, we recommend going for two tools from the
        G-Suite, i.e., Google Analytics and Google Search Console. While the first gives you access
        to both general and in-depth website and audience insights, the latter focuses on your
        site’s traffic, SEO ranks, and performance.{' '}
      </p>

      <p>
        As your business, customer base, and website complexity grow, you might be interested in
        implementing customer analytics, which we discuss next.
      </p>
      <h3>#3 Customer analytics</h3>

      <p>
        Customer analytics tools give you a more sophisticated view of your client base. By diving
        deep into your customers’ on-site behavior, psychographics, and purchasing history (among
        many other data), they help you better tailor your offer, increase on-site engagement, and
        boost conversions.
      </p>
      <h4>What tools to use?</h4>
      <p>
        We recommend looking into two customer analytics leaders –{' '}
        <Link href="/integrations/mixpanel/" target="_blank" rel="noopener noreferrer">
          Mixpanel
        </Link>{' '}
        and{' '}
        <Link href="/integrations/segment/" target="_blank" rel="noopener noreferrer">
          Segment
        </Link>
        .{' '}
      </p>

      <p>
        The first tool is famous for its ‘
        <Link
          href="/usability-testing/usability-testing-tools/"
          target="_blank"
          rel="noopener noreferrer"
        >
          innovation loop
        </Link>
        ’ functionality – it collects data from various tools, notices emerging trends, and helps
        you find out the reasons behind new behaviors so that you can use it to your business’s
        benefit. Segment, similarly to Mixpanel, collects data from various sources and can be used
        by your product team to make data-informed decisions on your product and UX.
      </p>
      <Img
        fluid={images.mixpanel.node.childImageSharp.fluid}
        alt="customer analytics tools make a great supplement to heat map software"
        title="Customer analytics"
      />
      <p>
        {' '}
        Source:{' '}
        <Link
          href="https://mixpanel.com/behavioral-analytics/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Mixpanel
        </Link>
      </p>

      <p>
        Which one is a better choice for you depends on your specific needs; nevertheless,
        regardless of your choice, you’re e able to integrate both of them with LiveSession for an
        all-in-one-tool customer analytics experience.
      </p>
      <h3>#4 A/B testing tools</h3>
      <p>
        A/B testing tools are yet another type of software that works perfectly with heat maps. They
        let you trial two versions of your site and decide which one drives better results. You can
        use this type of software both when you’re putting out a new page and when you’re looking to
        fix a current one.
      </p>

      <p>
        Let’s assume that a heat map on a landing page shows that it’s getting very few CTA clicks.{' '}
      </p>

      <p>
        Your product team draws up a hypothesis, for instance, that adding a fixed CTA banner to the
        top of the page will increase conversion by 15%. You set up heatmaps on two versions of the
        site – version ‘A’, which is the one you already have, and version ‘B’ – with the new
        concept. You can compare the results from both versions and either confirm or overthrow your
        hypothesis. Pretty neat, right?
      </p>
      <h4>What tools to use?</h4>
      <p>
        While there are a number of A/B testing tools on the market, we recommend going with{' '}
        <Link href="/integrations/google-optimize/" target="_blank" rel="noopener noreferrer">
          Google Optimize
        </Link>{' '}
        – not only does it integrate with your other tools within the G-Suite, but also the majority
        of heat map and session recording software, including LiveSession.
      </p>
      <Img
        fluid={images.goptimize.node.childImageSharp.fluid}
        alt="Google Optimize is a free A/B testing tool which you can integrate with the best heatmap tools out there"
        title="A/B testing tools"
      />

      <p>
        {' '}
        Source:{' '}
        <Link
          href="https://marketingplatform.google.com/about/optimize/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Google
        </Link>
      </p>
      <h3>#5 Website feedback</h3>

      <p>
        While heatmaps are a great source of insights on what is happening on your site, you might
        need to hear directly from users as to why they’re interacting with it in a certain way.
        Here’s where website feedback tools such as survey software come into play.
      </p>
      <p>
        Surveying your users will give you both quantitative and qualitative data to supplement your
        heat map analysis. For instance, you can ask your site visitors why they won’t scroll
        further down your page, or why they ignore the CTA.
      </p>

      <p>
        Set up a survey on the exact pages you’re using your heatmaps on. For pages where you’ve
        noticed an issue, you can ask a very specific question based on the user’s on-site activity.
        Alternatively, on crucial pages such as pricing or your homepage, you can always keep a
        general survey running, asking people whether they’d found what they were looking for.
      </p>
      <h4>What tools to use?</h4>
      <p>
        Some customer communication or CRM tools, such as Intercom and Hubspot, offer built-in
        surveys. However, bear in mind that they have lower customizability and follow-up logic than
        dedicated survey solutions. If you’d like to add custom questions or create complex
        follow-up question paths, we recommend using a tool like{' '}
        <Link href="/integrations/survicate/" target="_blank" rel="noopener noreferrer">
          Survicate
        </Link>
        , which integrates with LiveSession.
      </p>
      <Img
        fluid={images.survicate.node.childImageSharp.fluid}
        alt="website feedback surveys are a great supplement to your heatmap insights"
        title="Website feedback"
      />
      <p>
        Source:{' '}
        <Link
          href="https://survicate.com/surveys/templates/website-usability-survey/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Survicate
        </Link>
      </p>

      <h3>#6 Customer support</h3>
      <p>
        Last, but not least, you should use heat maps with customer support software. While they’re
        not, per se, related to optimizing the on-site experience, they certainly influence the
        customer experience. Chances are, you’ll find the reflection of the issues users report in
        your heatmap data in your conversations. For instance, if you were wondering why many people
        click on an image, by checking your customer conversations, you might learn that a number of
        users expect them to be clickable and take them to another webpage.
      </p>
      <p>
        That said, by integrating CS and heat map software, you’ll also be able to link heat maps to
        specific customer query tickets. As a result, your support team will be given more context
        on the issues and user behavior on each page.
      </p>
      <Banner
        title="Improve your checkout process based on data that really matters"
        desc="Find out when your Customers gets frustrated and improve your eCommerce UX. Test all LiveSession features for 14 days, no credit card required."
      />
      <h4>What tools to use?</h4>

      <p>
        There are a number of great tools out there – two which we can wholeheartedly recommend are{' '}
        <Link href="/integrations/livechat/" target="_blank" rel="noopener noreferrer">
          LiveChat
        </Link>{' '}
        or{' '}
        <Link href="/integrations/help-scout/" target="_blank" rel="noopener noreferrer">
          HelpScout
        </Link>
        . Both platforms allow you to integrate customer communication from various touchpoints into
        one panel. That said, LiveChat also gives you the ability to set up a chatbot, which can
        relieve your support teams of answering recurring questions.
      </p>
      <Img
        fluid={images.lc.node.childImageSharp.fluid}
        alt="best heatmap software integrates with customer support tools"
        title="Customer support"
      />
      <h2>Summary</h2>
      <p>
        Heatmaps are a great source of user intent and website usability insights. Among others,
        they let you conduct an audit of your site’s UX and better understand user activity. That
        said, they deliver the best results if used with a few other tools.
      </p>

      <p>
        First and foremost, the best heatmap software comes with other usability testing
        functionalities, such as user session recordings. Here’s where LiveSession might come
        especially in handy, as not only does it let you see the click and most noticed parts of the
        site, but also replay each user’s on-site behavior.
      </p>

      <p>
        Secondly, it’s worth using your heatmap solution with software such as website and customer
        analytics, A/B testing tools, surveys, and live chat. By cross-referencing your heatmaps
        findings with data from these platforms, you’ll be able to fill any information gaps and ace
        your website usability and CX.
      </p>

      <p>
        To give you a sense of all the types of tools you can integrate with heatmaps, refer to our{' '}
        <Link href="/integrations/" target="_blank" rel="noopener noreferrer">
          Integrations
        </Link>{' '}
        catalog.
      </p>
      <div className="next-chapter">
        <Link href="/heatmaps/ux-improvements/">
          <div>
            <p>Chapter 8</p>
            <p>11 experts share the UX improvements they achieved with heat maps</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(HeatMapSoftware);
